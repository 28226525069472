import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { styled } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';

const HeroBox = styled(Box)(({ theme }) => ({
  backgroundImage: 'linear-gradient(45deg, #7986cb 30%, #3f51b5 90%)',
  color: 'white',
  padding: theme.spacing(15, 0),
  textAlign: 'center',
}));

const HeroSection = () => {
  return (
    <HeroBox>
      <Container maxWidth="md">
        <Typography variant="h2" component="h1" gutterBottom>
          Turn Your Software Idea into Reality
        </Typography>
        <Typography variant="h5" paragraph>
          From concept to code — your guide to building the next big thing.
        </Typography>
        <Button 
          component={RouterLink}
          to="/login"
          variant="contained" 
          size="large" 
          color="secondary"
          sx={{ mt: 3 }}
        >
          Get Started
        </Button>
      </Container>
    </HeroBox>
  );
};

export default HeroSection;