import React from 'react';
import { 
  Paper, 
  Typography, 
  IconButton, 
  Box 
} from '@mui/material';
import { Close } from '@mui/icons-material';

const ArtifactViewer = ({ artifact, onClose }) => {
  return (
    <Paper 
      elevation={3} 
      sx={{ 
        height: 'calc(100vh - 200px)', 
        p: 2, 
        overflowY: 'auto',
        backgroundColor: 'background.paper',
        position: 'relative',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5" sx={{ color: 'text.primary' }}>
          {artifact.name}
        </Typography>
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </Box>
      <Typography variant="body1" sx={{ color: 'text.secondary', whiteSpace: 'pre-wrap' }}>
        {artifact.content}
      </Typography>
    </Paper>
  );
};

export default ArtifactViewer;