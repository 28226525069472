import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  IconButton,
  Box,
  Typography,
  Tooltip,
  Divider,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Dashboard as DashboardIcon,
  ExpandLess,
  ExpandMore,
  Add as AddIcon,
  Work as WorkIcon,
  Lightbulb as IdeaIcon,
  Chat as ChatIcon,
  Settings as SettingsIcon,
  ExitToApp as LogoutIcon,
} from '@mui/icons-material';

const SideMenu = ({
  theme,
  workspaces,
  selectedWorkspace,
  setSelectedWorkspace,
  selectedIdea,
  setSelectedIdea,
  selectedConversation,
  setSelectedConversation,
  onLogout,
}) => {
  const [open, setOpen] = useState(true);
  const [expandedWorkspace, setExpandedWorkspace] = useState(null);
  const [expandedIdea, setExpandedIdea] = useState(null);
  const [newWorkspaceDialogOpen, setNewWorkspaceDialogOpen] = useState(false);
  const [newIdeaDialogOpen, setNewIdeaDialogOpen] = useState(false);
  const [newWorkspaceName, setNewWorkspaceName] = useState('');
  const [newIdeaName, setNewIdeaName] = useState('');

  // Increased widths for both expanded and collapsed states
  const expandedWidth = 300;
  const collapsedWidth = 60;

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleWorkspaceSelect = (workspace) => {
    setSelectedWorkspace(workspace.WorkspaceId);
    setSelectedIdea(null);
    setSelectedConversation(null);
    setExpandedWorkspace(workspace.WorkspaceId);
  };

  const handleIdeaSelect = (idea) => {
    setSelectedIdea(idea.IdeaId);
    setSelectedConversation(null);
    setExpandedIdea(idea.IdeaId);
  };

  const handleConversationSelect = (conversation) => {
    setSelectedConversation(conversation);
  };

  const toggleWorkspaceExpand = (workspaceId) => {
    setExpandedWorkspace(expandedWorkspace === workspaceId ? null : workspaceId);
  };

  const toggleIdeaExpand = (ideaId) => {
    setExpandedIdea(expandedIdea === ideaId ? null : ideaId);
  };

  const handleNewWorkspace = () => {
    console.log('Creating new workspace:', newWorkspaceName);
    setNewWorkspaceDialogOpen(false);
    setNewWorkspaceName('');
  };

  const handleNewIdea = () => {
    console.log('Creating new idea:', newIdeaName);
    setNewIdeaDialogOpen(false);
    setNewIdeaName('');
  };

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        open={open}
        sx={{
          width: open ? expandedWidth : collapsedWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? expandedWidth : collapsedWidth,
            boxSizing: 'border-box',
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
          }}
        >
          <IconButton onClick={handleDrawerToggle}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Box>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          {workspaces.map((workspace) => (
            <React.Fragment key={workspace.WorkspaceId}>
              <ListItem 
                button 
                onClick={() => toggleWorkspaceExpand(workspace.WorkspaceId)}
                selected={selectedWorkspace === workspace.WorkspaceId}
              >
                <ListItemIcon>
                  <WorkIcon />
                </ListItemIcon>
                <ListItemText primary={workspace.name} />
                {expandedWorkspace === workspace.WorkspaceId ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={expandedWorkspace === workspace.WorkspaceId} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {workspace.ideas.map((idea) => (
                    <React.Fragment key={idea.IdeaId}>
                      <ListItem 
                        button 
                        sx={{ pl: 4 }} 
                        onClick={() => toggleIdeaExpand(idea.IdeaId)}
                        selected={selectedIdea === idea.IdeaId}
                      >
                        <ListItemIcon>
                          <IdeaIcon />
                        </ListItemIcon>
                        <ListItemText primary={idea.name} />
                        {expandedIdea === idea.IdeaId ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={expandedIdea === idea.IdeaId} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {idea.conversations.map((conversation) => (
                            <ListItem
                              key={conversation.ConversationId}
                              button
                              sx={{ pl: 6 }}
                              onClick={() => handleConversationSelect(conversation)}
                              selected={selectedConversation === conversation.ConversationId}
                            >
                              <ListItemIcon>
                                <ChatIcon />
                              </ListItemIcon>
                              <ListItemText primary={conversation.name} />
                            </ListItem>
                          ))}
                          <ListItem button sx={{ pl: 6 }}>
                            <ListItemIcon>
                              <AddIcon />
                            </ListItemIcon>
                            <ListItemText primary="New Conversation" />
                          </ListItem>
                        </List>
                      </Collapse>
                    </React.Fragment>
                  ))}
                  <ListItem button sx={{ pl: 4 }} onClick={() => setNewIdeaDialogOpen(true)}>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="New Idea" />
                  </ListItem>
                </List>
              </Collapse>
            </React.Fragment>
          ))}
          <ListItem button onClick={() => setNewWorkspaceDialogOpen(true)}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="New Workspace" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem button onClick={onLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>

      {/* New Workspace Dialog */}
      <Dialog open={newWorkspaceDialogOpen} onClose={() => setNewWorkspaceDialogOpen(false)}>
        <DialogTitle>Create New Workspace</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Workspace Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newWorkspaceName}
            onChange={(e) => setNewWorkspaceName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewWorkspaceDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleNewWorkspace} variant="contained" color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* New Idea Dialog */}
      <Dialog open={newIdeaDialogOpen} onClose={() => setNewIdeaDialogOpen(false)}>
        <DialogTitle>Create New Idea</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Idea Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newIdeaName}
            onChange={(e) => setNewIdeaName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewIdeaDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleNewIdea} variant="contained" color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SideMenu;