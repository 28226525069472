import React, { useState } from 'react';
import { 
  Box, 
  ThemeProvider, 
  createTheme, 
  CssBaseline,
  Snackbar,
  Alert,
  CircularProgress
} from '@mui/material';
import axios from 'axios';
import ConversationList from './ConversationList';
import MessageInput from './MessageInput';
import ArtifactViewer from './ArtifactViewer';
import SideMenu from './SideMenu';
import DesignerToolbar from './DesignerToolbar';

const API_BASE_URL = 'http://localhost:7109/api';

const SystemDesigner = ({ user, workspaces, darkMode, toggleDarkMode }) => {
  const [selectedArtifact, setSelectedArtifact] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState('');
  const [selectedIdea, setSelectedIdea] = useState('');
  const [selectedConversation, setSelectedConversation] = useState('');

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      background: {
        default: darkMode ? '#1e1e1e' : '#f5f5f5',
        paper: darkMode ? '#252526' : '#ffffff',
      },
      text: {
        primary: darkMode ? '#ffffff' : '#000000',
        secondary: darkMode ? '#cccccc' : '#666666',
      },
      primary: {
        main: '#007acc',
      },
    },
    typography: {
      fontFamily: '"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    },
  });

  const handleSendMessage = async (message) => {
    if (message.trim() && selectedConversation && user) {
      setIsLoading(true);
      setError(null);
      try {
        await axios.post(`${API_BASE_URL}/conversations/${selectedConversation.id}/messages`, {
          userId: user.UserId,
          role: 'user',
          content: message
        });
        // After sending a message, the ConversationList will automatically refresh
      } catch (err) {
        if (err.response && err.response.status === 402) {
          setError('Insufficient funds to send this message. Please add more credits to your account.');
        } else {
          setError('Failed to send message. Please try again.');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (!user || !workspaces) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <SideMenu
          theme={theme}
          workspaces={workspaces}
          selectedWorkspace={selectedWorkspace}
          setSelectedWorkspace={setSelectedWorkspace}
          selectedIdea={selectedIdea}
          setSelectedIdea={setSelectedIdea}
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
        />
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <DesignerToolbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} user={user} />
          <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
          </Box>
          <Box sx={{ display: 'flex', flexGrow: 1, p: 2, backgroundColor: 'background.default' }}>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <ConversationList
                conversation={selectedConversation}
                setSelectedArtifact={setSelectedArtifact}
              />
              <MessageInput
                onSendMessage={handleSendMessage}
                isLoading={isLoading}
                conversation={selectedConversation}
              />
            </Box>
            {selectedArtifact && (
              <Box sx={{ width: '30%', ml: 2 }}>
                <ArtifactViewer
                  artifact={selectedArtifact}
                  onClose={() => setSelectedArtifact(null)}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default SystemDesigner;