import React from 'react';
import { Box, Typography, Container, Stepper, Step, StepLabel, StepContent, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const steps = [
  {
    label: 'Brainstorm and outline your idea',
    description: 'Use our tools to capture and organize your thoughts. Break down your concept into manageable pieces.',
  },
  {
    label: 'Get feedback and refine your concept',
    description: 'Share your idea with our community of experts. Gather insights and improve your plan based on valuable feedback.',
  },
  {
    label: 'Plan and start development',
    description: 'Create a roadmap for your project. Begin building your MVP (Minimum Viable Product) with our guided development process.',
  },
];

const HowItWorksSection = () => {
  return (
    <Box sx={{ py: 8, bgcolor: 'background.paper' }}>
      <Container maxWidth="md">
        <Typography variant="h3" component="h2" textAlign="center" gutterBottom>
          How It Works
        </Typography>
        <StyledPaper elevation={3}>
          <Stepper orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label} active={true}>
                <StepLabel>
                  <Typography variant="h6">{step.label}</Typography>
                </StepLabel>
                <StepContent>
                  <Typography>{step.description}</Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </StyledPaper>
      </Container>
    </Box>
  );
};

export default HowItWorksSection;