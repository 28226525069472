import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Container } from '@mui/material';
import { Lightbulb, Layers, People } from '@mui/icons-material';

const Feature = ({ title, description, icon: Icon }) => (
  <Card elevation={3} sx={{ height: '100%' }}>
    <CardContent sx={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Icon sx={{ fontSize: 48, color: 'primary.main' }} />
      </Box>
      <Typography variant="h5" component="h3" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {description}
      </Typography>
    </CardContent>
  </Card>
);

const LandingFeaturesSection = () => {
  const features = [
    {
      title: "Idea Generation Tools",
      description: "Start with a blank canvas and let our tools help you brainstorm, structure, and validate your idea.",
      icon: Lightbulb
    },
    {
      title: "Step-by-Step Guidance",
      description: "Follow a guided process from idea validation to building your MVP.",
      icon: Layers
    },
    {
      title: "Collaboration",
      description: "Work with your team in real-time to bring your software idea to life.",
      icon: People
    }
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Typography variant="h3" component="h2" textAlign="center" gutterBottom>
          Features
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Feature {...feature} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingFeaturesSection;