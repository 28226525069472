import React, { useState, useEffect, useRef } from 'react';
import { 
  Paper, 
  List, 
  ListItem, 
  ListItemText, 
  Typography, 
  Button, 
  CircularProgress,
  Box
} from '@mui/material';
import { Description } from '@mui/icons-material';
import axios from 'axios';

const API_BASE_URL = 'http://localhost:7109/api';

const ConversationList = ({ conversation, setSelectedArtifact }) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (conversation.id) {
      fetchMessages();
    }
  }, [conversation.id]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchMessages = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_BASE_URL}/conversations/${conversation.id}/messages`);
      console.log(response);
      setMessages(response.data.messages);
    } catch (err) {
      setError('Failed to fetch messages. Please try again.');
      console.error('Error fetching messages:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const renderMessageContent = (message) => {
    const parts = message.content.split(/(\[artifact:\d+\])/);
    return parts.map((part, index) => {
      const artifactMatch = part.match(/\[artifact:(\d+)\]/);
      if (artifactMatch) {
        const artifactId = parseInt(artifactMatch[1], 10);
        const artifact = message.artifacts ? message.artifacts.find(a => a.id === artifactId) : null;
        if (artifact) {
          return (
            <Button
              key={index}
              variant="outlined"
              size="small"
              startIcon={<Description />}
              onClick={() => setSelectedArtifact(artifact)}
              sx={{ mx: 1, my: 0.5, color: 'primary.main', borderColor: 'primary.main' }}
            >
              {artifact.name}
            </Button>
          );
        }
      }
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        height: 'calc(100vh - 200px)', 
        p: 2, 
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.paper',
        overflowY: 'auto',
      }}
    >
      <Typography variant="h5" gutterBottom sx={{ color: 'text.primary' }}>
        { conversation.name }
      </Typography>
      {isLoading && <CircularProgress sx={{ alignSelf: 'center', my: 2 }} />}
      {error && (
        <Typography color="error" sx={{ my: 2 }}>
          {error}
        </Typography>
      )}
      <List sx={{ flexGrow: 1, overflowY: 'auto', mb: 2 }}>
        {messages.map((message, index) => (
          <ListItem 
            key={index} 
            alignItems="flex-start" 
            sx={{ 
              py: 2,
              px: 2,
              my: 1,
              backgroundColor: message.role === 'user' ? 'rgba(0, 122, 204, 0.1)' : 'rgba(255, 255, 255, 0.05)',
              borderRadius: 2,
              boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            }}
          >
            <ListItemText
              primary={
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'text.primary', mb: 1 }}>
                  {message.role === 'user' ? 'You' : 'AI'}
                </Typography>
              }
              secondary={
                <Typography
                  component="span"
                  variant="body1"
                  sx={{ 
                    color: 'text.secondary',
                    '& span': { whiteSpace: 'pre-wrap' }
                  }}
                >
                  {renderMessageContent(message)}
                </Typography>
              }
            />
          </ListItem>
        ))}
        <Box ref={messagesEndRef} />
      </List>
    </Paper>
  );
};

export default ConversationList;