import React from 'react';
import { Box, Typography, Container, Grid, Card, CardContent, Avatar } from '@mui/material';
import { FormatQuote } from '@mui/icons-material';
import { styled } from '@mui/system';

const QuoteIcon = styled(FormatQuote)(({ theme }) => ({
  fontSize: '3rem',
  color: theme.palette.primary.main,
  opacity: 0.3,
}));

const testimonials = [
  {
    quote: "This app helped me turn my rough idea into a polished product. I couldn't have done it without the guidance!",
    author: "Sarah Johnson",
    role: "Startup Founder",
    avatar: "/path/to/sarah-avatar.jpg", // Replace with actual path
  },
  {
    quote: "An essential tool for anyone serious about developing their software ideas. It streamlined our entire process.",
    author: "Michael Chen",
    role: "Senior Developer",
    avatar: "/path/to/michael-avatar.jpg", // Replace with actual path
  },
  {
    quote: "The collaborative features made it easy for our remote team to work together efficiently. Highly recommended!",
    author: "Emily Rodriguez",
    role: "Project Manager",
    avatar: "/path/to/emily-avatar.jpg", // Replace with actual path
  },
];

const TestimonialCard = ({ quote, author, role, avatar }) => (
  <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box>
        <QuoteIcon />
        <Typography variant="body1" paragraph>
          "{quote}"
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Avatar src={avatar} alt={author} sx={{ width: 56, height: 56, mr: 2 }} />
        <Box>
          <Typography variant="subtitle1" component="p" fontWeight="bold">
            {author}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {role}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

const TestimonialsSection = () => {
  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Typography variant="h3" component="h2" textAlign="center" gutterBottom>
          What Our Users Say
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} md={4} key={index}>
              <TestimonialCard {...testimonial} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default TestimonialsSection;