import React from 'react';
import { ThemeProvider, createTheme, CssBaseline, Container } from '@mui/material';
import LandingHeader from './components/LandingHeader';
import HeroSection from './components/HeroSection';
import LandingFeaturesSection from './components/LandingFeaturesSection';
import HowItWorksSection from './components/HowItWorksSection';
import TestimonialsSection from './components/TestimonialsSection';
import LandingFooter from './components/LandingFooter';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

function Landing() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth={false} disableGutters>
        <LandingHeader />
        <HeroSection />
        <LandingFeaturesSection />
        <HowItWorksSection />
        <TestimonialsSection />
        <LandingFooter />
      </Container>
    </ThemeProvider>
  );
}

export default Landing;