import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  IconButton, 
  Box, 
  Container,
  useMediaQuery,
  Menu,
  MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';

const LandingHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = ['Home', 'Features', 'Pricing', 'Contact'];

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box to="/" component={RouterLink}  sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, userSelect: 'none', textDecoration: 'none', color: 'inherit', '&:hover': { cursor: 'pointer' } }}>
            <img 
              src="logo512nobg-dark.png" 
              alt="Concept Crunch" 
              style={{ height: 74, marginRight: theme.spacing(1) }}
            />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, userSelect: 'none'}}>
              Concept Crunch
            </Typography>
          </Box>

          {isMobile ? (
            <>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {menuItems.map((item) => (
                  <MenuItem key={item} onClick={handleClose}>{item}</MenuItem>
                ))}
                <MenuItem onClick={handleClose}>
                  <Button variant="contained" color="primary" fullWidth>
                    Sign In
                  </Button>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {menuItems.map((item) => (
                  <Button key={item} color="inherit" sx={{ mx: 1 }}>
                    {item}
                  </Button>
                ))}
                <Button component={RouterLink}  
                  to="/login"  variant="contained" color="primary" sx={{ ml: 2 }}>
                  Sign In
                </Button>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default LandingHeader;