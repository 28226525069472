import React, { useState } from 'react';
import { Box, Typography, ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import NextSteps from './components//NextSteps';
import DesignerToolbar from './DesignerToolbar';

const FinalSummary = () => {
  const [darkMode, setDarkMode] = useState(false);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <DesignerToolbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
        <Box sx={{ maxWidth: 'lg', mx: 'auto', mt: 4, mb: 8, flexGrow: 1 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Your Project Summary
          </Typography>
          
          <NextSteps />

        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FinalSummary;