import React from 'react';
import { Box, Button, Typography, Paper, Tooltip } from '@mui/material';
import {
  LightbulbOutlined,
  Search,
  Feedback,
  Brush,
  MonetizationOn,
  Build,
  Launch
} from '@mui/icons-material';

const menuItems = [
  {
    id: 'conceptDevelopment',
    primaryText: 'Concept Development',
    icon: <LightbulbOutlined />,
    subItems: [
      { 
        id: 'criticalConceptClarification', 
        text: 'Critical Concept Clarification',
        hint: `Dive deep into your core idea. Identify the key problem you're solving and articulate your unique solution. Consider the main value proposition and how it differs from existing solutions. This step helps solidify the foundation of your product concept.`
      },
      { 
        id: 'conceptValidation', 
        text: 'Concept Validation',
        hint: 'Test your concept with potential users or experts in the field. Gather feedback on the viability and desirability of your idea. Use techniques like problem interviews, solution interviews, or landing page tests to validate key assumptions about your concept.'
      },
      { 
        id: 'identifyingUsers', 
        text: 'Identifying Users',
        hint: 'Define your target user segments. Create detailed user personas that include demographics, psychographics, needs, and pain points. Understanding your users deeply will help guide product development and marketing strategies.'
      },
    ]
  },
  {
    id: 'marketResearch',
    primaryText: 'Market Research',
    icon: <Search />,
    subItems: [
      { 
        id: 'competitionAnalysis', 
        text: 'Competition Analysis',
        hint: 'Identify and analyze your direct and indirect competitors. Evaluate their strengths, weaknesses, and market positioning. Look for gaps in the market that your product can fill. This analysis will help you refine your unique value proposition.'
      },
      { 
        id: 'definingDifferentiation', 
        text: 'Defining Differentiation',
        hint: 'Based on your competition analysis, clearly articulate what sets your product apart. This could be features, pricing, user experience, or target audience. Your differentiation strategy will be crucial for marketing and positioning your product.'
      },
      { 
        id: 'marketSizingAndSegmentation', 
        text: 'Market Sizing and Segmentation',
        hint: 'Estimate the total addressable market (TAM), serviceable addressable market (SAM), and serviceable obtainable market (SOM) for your product. Segment your market based on relevant criteria. This will help in targeting your marketing efforts and scaling strategies.'
      },
    ]
  },
  {
    id: 'userFeedback',
    primaryText: 'User Feedback',
    icon: <Feedback />,
    subItems: [
      { 
        id: 'feedbackFromActualUsers', 
        text: 'Feedback from Actual Users',
        hint: 'Engage with potential users through surveys, interviews, or focus groups. Gather insights on their needs, preferences, and pain points. Use this feedback to refine your product concept and features. Continuous user feedback is crucial for product-market fit.'
      },
      { 
        id: 'userPersonaCreation', 
        text: 'User Persona Creation',
        hint: 'Develop detailed user personas based on your research and feedback. Include demographics, behaviors, goals, and challenges for each persona. These personas will guide product development, UX design, and marketing strategies.'
      },
      { 
        id: 'userJourneyMapping', 
        text: 'User Journey Mapping',
        hint: 'Create user journey maps to visualize how users will interact with your product. Identify key touchpoints, pain points, and opportunities for improvement. This will help in designing a seamless user experience and identifying critical features.'
      },
    ]
  },
  {
    id: 'designAndPrototyping',
    primaryText: 'Design and Prototyping',
    icon: <Brush />,
    subItems: [
      { 
        id: 'lowFidelityWireframes', 
        text: 'Low-Fidelity Wireframes',
        hint: 'Create basic wireframes to outline the structure and layout of your product. Focus on information architecture and user flow without getting caught up in visual design details. Use these wireframes to quickly iterate on your product concept.'
      },
      { 
        id: 'highFidelityPrototype', 
        text: 'High-Fidelity Prototype',
        hint: 'Develop a more detailed, interactive prototype that closely resembles the final product. Include visual design elements, interactions, and key functionality. This prototype will be used for user testing and can help in pitching to investors or stakeholders.'
      },
      { 
        id: 'usabilityTesting', 
        text: 'Usability Testing',
        hint: 'Conduct usability tests with your prototype to identify any user experience issues. Observe how users interact with your product and gather feedback. Use these insights to refine your design and improve overall usability before moving to development.'
      },
    ]
  },
  {
    id: 'businessPlanning',
    primaryText: 'Business Planning',
    icon: <MonetizationOn />,
    subItems: [
      { 
        id: 'businessModelCanvas', 
        text: 'Business Model Canvas',
        hint: 'Create a Business Model Canvas to visualize your business model. Define key partners, activities, resources, value propositions, customer relationships, channels, customer segments, cost structure, and revenue streams. This exercise helps in understanding and communicating your business model effectively.'
      },
      { 
        id: 'financialProjections', 
        text: 'Financial Projections',
        hint: 'Develop detailed financial projections including income statements, balance sheets, and cash flow statements. Consider different scenarios (optimistic, realistic, pessimistic) and include key metrics like customer acquisition cost (CAC) and lifetime value (LTV). These projections are crucial for planning and attracting investors.'
      },
      { 
        id: 'pricingStrategy', 
        text: 'Pricing Strategy',
        hint: 'Determine your pricing strategy based on your costs, competitor pricing, and perceived value. Consider different models like freemium, subscription, or usage-based pricing. Your pricing strategy should align with your overall business model and target market.'
      },
    ]
  },
  {
    id: 'development',
    primaryText: 'Development',
    icon: <Build />,
    subItems: [
      { 
        id: 'mvpDevelopment', 
        text: 'MVP Development',
        hint: `Plan and develop your Minimum Viable Product (MVP). Focus on core features that deliver your key value proposition. The MVP should be sufficient to validate your main assumptions and attract early adopters. Remember, it's about launching quickly and iterating based on user feedback.`
      },
      { 
        id: 'techStackSelection', 
        text: 'Tech Stack Selection',
        hint: 'Choose the appropriate technologies for your product development. Consider factors like scalability, performance, developer availability, and long-term maintenance. Your tech stack should align with your product requirements and business goals.'
      },
      { 
        id: 'developmentRoadmap', 
        text: 'Development Roadmap',
        hint: 'Create a detailed roadmap for your product development. Break down the process into sprints or milestones. Prioritize features based on their importance and complexity. This roadmap will guide your development process and help in managing resources effectively.'
      },
    ]
  },
  {
    id: 'launchAndGrowth',
    primaryText: 'Launch and Growth',
    icon: <Launch />,
    subItems: [
      { 
        id: 'marketingStrategy', 
        text: 'Marketing Strategy',
        hint: 'Develop a comprehensive marketing strategy to reach your target audience. Include a mix of digital marketing, content marketing, PR, and potentially traditional marketing channels. Define your brand voice and messaging. Create a content calendar and plan for ongoing marketing activities.'
      },
      { 
        id: 'launchPlan', 
        text: 'Launch Plan',
        hint: 'Create a detailed launch plan including pre-launch, launch day, and post-launch activities. Consider a soft launch or beta testing phase. Plan for user onboarding, support, and gathering initial feedback. Your launch plan should aim to create buzz and attract your first set of users or customers.'
      },
      { 
        id: 'growthMetrics', 
        text: 'Growth Metrics and KPIs',
        hint: `Define key performance indicators (KPIs) to track your product's growth and success. This might include user acquisition rate, retention rate, conversion rate, and customer lifetime value. Set up analytics tools to monitor these metrics. Regularly review and adjust your strategies based on these metrics.`
      },
    ]
  },
];

const NextSteps = () => {
  const allSubItems = menuItems.flatMap(item => 
    item.subItems.map(subItem => ({
      ...subItem,
      icon: item.icon
    }))
  );

  const nextSteps = allSubItems.slice(0, 4);

  const handleItemClick = (item) => {
    // Here you would typically navigate to the corresponding component
    console.log(`Navigate to component for: ${item.text}`);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h5" gutterBottom>Next Steps</Typography>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={2}>
        {nextSteps.map((item) => (
          <Tooltip key={item.id} title={item.hint} arrow placement="top">
            <Button
              variant="outlined"
              onClick={() => handleItemClick(item)}
              startIcon={item.icon}
              sx={{ flexGrow: 1, flexBasis: '40%', justifyContent: 'flex-start' }}
            >
              {item.text}
            </Button>
          </Tooltip>
        ))}
      </Box>
    </Paper>
  );
};

export default NextSteps;