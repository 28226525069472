import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  IconButton, 
  Menu, 
  MenuItem,
  Box,
  Avatar,
  Chip,
} from '@mui/material';
import { 
  Brightness4 as DarkModeIcon, 
  Brightness7 as LightModeIcon,
  AccountBalanceWallet as WalletIcon
} from '@mui/icons-material';
import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';

const DesignerToolbar = ({ darkMode, toggleDarkMode }) => {
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { user, balance } = useUser();
  const name = user ? user.name : '';

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    handleClose();
    instance.logoutPopup().then(() => {
      navigate('/');
    });
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <img src="/logo512nobg.png" alt="Logo" style={{ height: 40, marginRight: 16 }} />
          <Typography variant="h6" component="div">
            Concept Crunch
          </Typography>
        </Box>
        <IconButton color="inherit" onClick={toggleDarkMode} sx={{ mr: 2 }}>
          {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>
        <Chip
          icon={<WalletIcon />}
          label={`$${balance.toFixed(3)}`}
          color="secondary"
          sx={{ mr: 2 }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, '&:hover': { cursor: 'pointer' }}} 
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}>
          <Typography variant="body1">
            {name}
          </Typography>
          <Avatar sx={{ width: 32, height: 32, ml: 1, bgcolor: 'secondary.main' }}>
            {name.charAt(0).toUpperCase()}
          </Avatar>
        </Box>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default DesignerToolbar;