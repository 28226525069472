import React, { useState, useEffect } from 'react';
import { 
  ThemeProvider,
  createTheme,
  CssBaseline,
  CircularProgress,
  Box
} from '@mui/material';
import SystemDesigner from './SystemDesigner';
import { useUser } from './UserContext';
import axios from 'axios';

const API_BASE_URL = 'http://localhost:7109/api';

const RootSignedIn = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useUser();

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    const fetchWorkspaces = async () => {
      if (user && user.UserId) {
        try {
          setIsLoading(true);
          const response = await axios.get(`${API_BASE_URL}/users/${user.UserId}/workspaces`);
          setWorkspaces(response.data);
        } catch (error) {
          console.error('Error fetching workspaces:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchWorkspaces();
  }, [user]);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <main>
          <SystemDesigner 
            user={user}
            workspaces={workspaces} 
            darkMode={darkMode} 
            toggleDarkMode={toggleDarkMode}
          />
        </main>
      </div>
    </ThemeProvider>
  );
};

export default RootSignedIn;