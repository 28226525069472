import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  IconButton, 
  InputAdornment 
} from '@mui/material';
import { Send, AttachFile } from '@mui/icons-material';

const MessageInput = ({ onSendMessage, isLoading, conversation }) => {
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleSendMessage = () => {
    if (userInput.trim()) {
      onSendMessage(userInput);
      setUserInput('');
    }
  };

  const handleAttachment = () => {
    console.log('Attachment button clicked');
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Type your message..."
        value={userInput}
        onChange={handleInputChange}
        disabled={isLoading || !conversation}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleAttachment} disabled={isLoading || !conversation}>
                <AttachFile />
              </IconButton>
              <IconButton onClick={handleSendMessage} disabled={isLoading || !userInput.trim() || !conversation}>
                <Send />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default MessageInput;