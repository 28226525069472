import React, { useState, useEffect } from 'react';
import { 
  ThemeProvider,
  createTheme,
  CssBaseline,
} from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MsalProvider, useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';

import { UserProvider, useUser } from './UserContext';
import Landing from './components/landing/Landing';
import RootSignedIn from './RootSignedIn';
import FinalSummary from './FinalSummary';
import LoginPage from './components/LoginPage';

const API_BASE_URL = 'http://localhost:7109/api';

const msalInstance = new PublicClientApplication(msalConfig);

const AppContent = () => {
  const { instance, accounts } = useMsal();
  const { user, setUser, fetchUserInfo } = useUser();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAndSetUser = async () => {
      if (accounts.length > 0 && !user) {
        try {
          const response = await axios.post(`${API_BASE_URL}/users/create-or-fetch`, {
            email: accounts[0].username,
            name: accounts[0].name,
          });
          setUser(response.data);
          await fetchUserInfo(response.data.UserId);
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    checkAndSetUser();
  }, [accounts, user, setUser, fetchUserInfo]);

  const [darkMode] = useState(false);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <AuthenticatedTemplate>
          <Routes>
            <Route path="/" element={<Navigate to="/designer" />} />
            <Route path="/designer" element={<RootSignedIn />} />
            <Route path="/final-summary" element={<FinalSummary />} />
          </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </UnauthenticatedTemplate>
      </div>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <Router>
      <MsalProvider instance={msalInstance}>
        <UserProvider>
          <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
            <AppContent />
          </GoogleOAuthProvider>
        </UserProvider>
      </MsalProvider>
    </Router>
  );
};

export default App;