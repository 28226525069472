import React, { useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Link,
  Grid,
  Paper,
  Alert,
  CircularProgress
} from '@mui/material';
import { 
  Google as GoogleIcon, 
  GitHub as GitHubIcon, 
  LinkedIn as LinkedInIcon,
  Microsoft as MicrosoftIcon
} from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';

const API_BASE_URL = 'http://localhost:7109/api';

const SocialButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(1),
  },
}));

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const { setUser } = useUser();

  const createOrFetchUser = async (userDetails) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/users/create-or-fetch`, userDetails);
      return response.data;
    } catch (error) {
      console.error('Error creating or fetching user:', error);
      throw error;
    }
  };

  const handleLoginSuccess = async (loginResponse) => {
    setIsLoading(true);
    try {
      const userDetails = {
        email: loginResponse.account.username,
        name: loginResponse.account.name,
      };

      const userData = await createOrFetchUser(userDetails);
      setUser(userData.User);
      navigate('/designer');
    } catch (error) {
      setError('Failed to initialize user data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    if (!email) {
      setError('Please enter your email address.');
      return;
    }

    try {
      const request = {
        ...loginRequest,
        loginHint: email
      };

      const response = await instance.loginPopup(request);
      await handleLoginSuccess(response);
    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed. Please try again.');
    }
  };

  const handleMicrosoftLogin = async () => {
    try {
      const response = await instance.loginPopup(loginRequest);
      await handleLoginSuccess(response);
    } catch (error) {
      console.error('Error during Microsoft login:', error);
      setError('Microsoft login failed. Please try again.');
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        // Here you would typically send the access token to your backend
        // to verify and create a session
        const userDetails = {
          // You might need to make an additional request to Google to get user details
          email: 'google_user@example.com', // This is a placeholder
          name: 'Google User', // This is a placeholder
        };
        await handleLoginSuccess({ account: userDetails });
      } catch (error) {
        console.error('Error during Google login:', error);
        setError('Google login failed. Please try again.');
      }
    },
    onError: (error) => {
      console.error('Google Login Error:', error);
      setError('Google login failed. Please try again.');
    }
  });

  const handleGitHubLogin = () => {
    // Implement GitHub login logic here
    console.log('GitHub login not implemented');
  };

  const handleLinkedInLogin = () => {
    // Implement LinkedIn login logic here
    console.log('LinkedIn login not implemented');
  };

  if (accounts.length > 0) {
    navigate("/designer");
    return null;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ mt: 8, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Sign in to Concept Crunch
        </Typography>
        {error && <Alert severity="error" sx={{ mt: 2, width: '100%' }}>{error}</Alert>}
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isLoading}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Sign In'}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ my: 3, width: '100%' }}>
          <Typography variant="body2" color="text.secondary">
            OR
          </Typography>
        </Divider>
        <Box sx={{ mt: 1, width: '100%' }}>
          <SocialButton
            startIcon={<MicrosoftIcon />}
            variant="outlined"
            onClick={handleMicrosoftLogin}
            disabled={isLoading}
          >
            Sign in with Microsoft
          </SocialButton>
          <SocialButton
            startIcon={<GoogleIcon />}
            variant="outlined"
            onClick={() => googleLogin()}
            disabled={isLoading}
          >
            Sign in with Google
          </SocialButton>
          <SocialButton
            startIcon={<GitHubIcon />}
            variant="outlined"
            onClick={handleGitHubLogin}
            disabled={isLoading}
          >
            Sign in with GitHub
          </SocialButton>
          <SocialButton
            startIcon={<LinkedInIcon />}
            variant="outlined"
            onClick={handleLinkedInLogin}
            disabled={isLoading}
          >
            Sign in with LinkedIn
          </SocialButton>
        </Box>
      </Paper>
    </Container>
  );
};

export default LoginPage;