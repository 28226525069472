import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

const API_BASE_URL = 'http://localhost:7109/api';

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(0);

  const fetchUserInfo = async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users/${userId}/info`);
      setUser(response.data.User);
      setBalance(response.data.Balance);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, balance, setBalance, fetchUserInfo }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};